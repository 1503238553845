<template>
  <tbody v-if="$wait.is('response')">
    <tr>
      <td 
        v-for="(column, index) in filteredColumns" 
        :key="index"
      >
        <content-placeholder />
      </td>
    </tr>
  </tbody>
  <tbody 
    v-else 
    data-test-id="history-table-motorcheck"
  >
    <GridTooltip
      id="grid-tooltip"
      style="display: none"
      v-tippy-html
      v-bind="{ grid }"
    />
    <tr 
      v-for="(item, index) in rows"
      :key="index"
    >
      <td>
        <button class="button is-dark has-text-dark has-background-white is-outlined is-disabled">
          {{ item.vrm }}
        </button>
      </td>
      <td class="is-hidden-touch">
        {{ truncate(item.description || '') }}
      </td>
      <td>
        <span class="cell-date">{{ formatDate(item.createdAt) }}</span>
      </td>
      <td 
        v-if="showUserColumn" 
        class="is-hidden-touch"
      >
        {{ item.user?.name }}
      </td>
      <td 
        v-if="showBranchColumn"
        class="is-hidden-touch"
      >
        {{ item.branch?.name }}
      </td>
      <td>
        <a
          v-if="item.issues"
          @mouseover="updateTooltip(item)"
          v-tippy="{ html: '#grid-tooltip' }"
        >
          <span
            class="status-tag tag is-medium is-size-7-mobile has-text-weight-bold"
            :class="`is-${checkStatus(item).class}`"
          >{{ checkStatus(item).text }}</span>
        </a>
        <span
          v-else
          class="tag has-border is-light is-medium is-size-7-mobile has-text-grey-light has-text-weight-semibold"
        >
          N/A
        </span>
      </td>
      <td>
        <div class="buttons are-small">
          <a
            v-if="item.url"
            :href="item.url"
            target="_blank"
            rel="noopener noreferrer"
            class="button is-size-7-mobile is-info is-light"
            @click="track('external_check')"
          >
            <span class="icon">
              <i class="fal fa-external-link" />
            </span>
            <span>View</span>
          </a>
          <div
            class="dropdown is-hoverable"
            v-if="item.pdf || item.certificatePdf || item.silentSalesPdf || item.environmentalPdf"
          >
            <div class="dropdown-trigger">
              <button class="button is-size-7-mobile is-soft">
                <span class="icon">
                  <i class="fal fa-file-pdf" />
                </span>
                <span>Download</span>
                <span class="icon">
                  <i class="fal fa-angle-down" />
                </span>
              </button>
            </div>
            <div class="dropdown-menu">
              <div class="dropdown-content">
                <a 
                  v-if="item.pdf"
                  :href="item.pdf"
                  class="dropdown-item"
                  target="_blank"
                  @click="track('pdf_download')"
                >
                  Full Report
                </a>
                <a 
                  v-if="item.certificatePdf"
                  :href="item.certificatePdf"
                  class="dropdown-item"
                  target="_blank"
                  @click="track('certificate_download')"
                >
                  Certificate
                </a>
                <a 
                  v-if="item.silentSalesPdf"
                  :href="item.silentSalesPdf"
                  class="dropdown-item"
                  target="_blank"
                  @click="track('silent_sales_download')"
                >
                  Silent Sales
                </a>
                <a 
                  v-if="item.environmentalPdf"
                  :href="item.environmentalPdf"
                  class="dropdown-item"
                  target="_blank"
                  @click="track('environmental_download')"
                >
                  Environmental
                </a>
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script>
import { formatDate } from '@/utils/date.utils.ts'
import truncate from 'truncate'
import { mapGetters } from 'vuex'
import * as motorcheck from 'modules/motorcheck/services'
import GridTooltip from 'modules/motorcheck/components/MotorCheckGridTooltip'

export default {
  name: 'AccountHistoryMotorcheck',
  components: {
    contentPlaceholder: () => import('core/components/ContentPlaceholder'),
    GridTooltip
  },
  data: () => ({ tooltipData: null }),
  props: {
    depth: {
      type: String,
      required: false,
      default: () => 'user'
    },
    preview: {
      type: Boolean,
      default: () => false
    },
    rows: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters('auth', ['hasMotorCheck']),
    grid() {
      if (!this.tooltipData) {
        return {
          items: [],
          total: 0
        }
      }
      return motorcheck.getGrid(this.tooltipData)
    },
    showUserColumn() {
      return this.depth !== 'user' && this.columns.some(col => col.field === 'user')
    },
    showBranchColumn() {
      return this.depth === 'org' && this.columns.some(col => col.field === 'branch')
    },
    filteredColumns() {
      let cols = [...this.columns]
      
      if (!this.showUserColumn) {
        cols = cols.filter(col => col.field !== 'user')
      }
      
      if (!this.showBranchColumn) {
        cols = cols.filter(col => col.field !== 'branch')
      }
      
      return cols
    }
  },
  methods: {
    formatDate,
    truncate,
    track(action) {
      this.$mxp.track(`${this.depth}_history_motorcheck_${action}`)
    },
    checkStatus(item) {
      return motorcheck.getStatus(item.issues)
    },
    updateTooltip(item) {
      this.tooltipData = item
    }
  }
}
</script>

<style lang="sass" scoped>
.status-tag
  min-width: 100px
  width: fit-content
  justify-content: center
  display: inline-flex
  align-items: center
  padding: 0 0.75em
  white-space: nowrap
  height: 2em

  max-width: 200px
  
  &.is-medium
    font-size: 1rem
    @media screen and (max-width: 768px)
      font-size: 0.875rem
</style>
