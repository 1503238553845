import { node } from '@/plugins/axios'


export const getStatus = issues => {
  // Check danger alerts
  const atRisk = issues?.atRisk?.flag ?? false
  const stolen = issues?.stolen?.flag ?? false
  const scrapped = issues?.scrapped?.flag ?? false
  const writeOff = issues?.writeOff?.flag ?? false
  const danger = atRisk || stolen || scrapped || writeOff

  // Check warning alerts
  const finance = issues?.finance?.flag ?? false
  const keepers = issues?.keepers?.flag ?? false
  const mileage = issues?.mileage?.flag ?? false
  const motHistory = issues?.motHistory?.flag ?? false
  const taxAndSorn = issues?.taxAndSorn?.flag ?? false
  const plateChange = issues?.plateChange?.flag ?? false
  const conditionAlerts = issues?.conditionAlerts?.flag ?? false
  const colourChanges = issues?.colourChanges?.flag ?? false
  const warning = finance || keepers || mileage || motHistory || taxAndSorn || conditionAlerts || plateChange || colourChanges

  // Return appropriate status
  if (danger) {
    return { text: 'Warning', class: 'danger' }
  } else if (warning) {
    return { text: 'Caution', class: 'warning' }
  } else {
    return { text: 'Clear', class: 'success' }
  }
}

export const getGrid = response => {
  if (!response) {
    return null
  }
  
  const issues = response?.issues

  return {
    stolen: issues?.stolen?.flag ?? false,
    risk: issues?.atRisk?.flag ?? false,
    finance: issues?.finance?.flag ?? false,
    scrapped: issues?.scrapped?.flag ?? false,
    writeOff: issues?.writeOff?.flag ?? false,
    mileage: issues?.mileage?.flag ?? false,
    keepers: issues?.keepers?.flag ?? false,
    motHistory: issues?.motHistory?.flag ?? false,
    taxAndSorn: issues?.taxAndSorn?.flag ?? false,
    conditionAlerts: issues?.conditionAlerts?.flag ?? false,
    plateChange: issues?.plateChange?.flag ?? false,
    colourChanges: issues?.colourChanges?.flag ?? false
  }
}

export const getReport = async ({ vrm, mileage }) => {
  try {
    const params = { vrm }
    if (mileage) params.mileage = mileage
    
    const { data } = await node.get('/provenance/motorcheck/report', { params })
    return data
  } catch (err) {
    throw err
  }
}
